<template>
  <div class="balance-less-view">
    <div class="title"><i class="el-icon-warning"></i> 余额已不足 <span>300</span> 元</div>
    <div class="info">为保障您的服务正常运行，请尽快联系管理员进行充值！</div>
    <div class="admin-dialog-btns">
      <el-button
        type="danger"
        @click="
          () => {
            this.$emit('end');
          }
        "
        >知道了
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    num: Number,
  },
  data() {
    return {};
  },
  created() {
    // if(this.$props.row)
  },
};
</script>

<style lang="scss">
.balance-less-view {
  text-align: center;
  .title {
    font-size: 24px;
    i {
      font-size: 30px;
      color: $--color-danger;
      vertical-align: bottom;
    }
    span {
      color: $--color-danger;
    }
  }
  .info {
    font-size: 16px;
    margin: 25px 0;
  }
}
</style>
